<template>
    <div class="escaperoom pt-5">
        <div class="escaperoom__information pt-5">
            <div class="container px-4 px-lg-3">
                <div class="row flex-column-reverse flex-md-row">
                    <div class="col-12 col-md-9 mb-4">
                        <div class="pb-4" v-if="room.id === 1">
                            <h2>{{ __('Het verhaal') }}</h2><br />
                            {{ __('De wereldberoemde wetenschapper Sander Klaus en zijn team geloven dat er een micro-organisme vastzit onder lagen van duizend jaar oud ijs in een poolgebied.') }}
                            {{ __('Hij heeft zijn leven aan dit onderzoek gewijd en na maanden zonder nieuwe bevindingen besluit het team een nieuwe, jonge onderzoeker aan het team toe te voegen en dat is precies wat ze nodig hadden. Als de jonge professor een doorbraak heeft, blijkt niet alle hoop verloren te zijn. Maar net als het er veelbelovend uit begint te zien, verliest het team het contact met hem. Hij lijkt spoorloos te zijn verdwenen.') }}<br />
                            <div class="row mx-0 my-4">
                                <div class="col-10 offset-1 offset-md-0 col-md-4 px-0 pr-md-1 mt-2 mt-md-0">
                                    <img :src="screenENOne" alt="Expeditie Noordpool screenshot" style="cursor: pointer;" @click="openLightbox(1)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-4 px-0 px-md-1 mt-2 mt-md-0">
                                    <img :src="screenENTwo" alt="Expeditie Noordpool gameplay" style="cursor: pointer;" @click="openLightbox(2)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-4 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenENThree" alt="Expeditie Noordpool online escape room" style="cursor: pointer;" @click="openLightbox(3)" loading="lazy"/>
                                </div>
                            </div>
                            {{ __('Met weinig opties en nog minder tijd wordt een team van onderzoekers ingehuurd om deze huiveringwekkende verdwijning te onderzoeken. Kun jij je hoofd koel houden en erachter komen wat er met de professor is gebeurd?') }}
                        </div>
                        <div class="pb-4" v-if="room.id === 2">
                            <h2>{{ __('Het verhaal') }}</h2><br />
                            {{ __('underground-murder.story') }}<br />
                            <div class="row mx-0 my-4">
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pr-md-1 mt-2 mt-md-0">
                                    <img :src="screenUMOne" alt="Ondergrondse Moord screenshot" style="cursor: pointer;" @click="openLightbox(1)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 px-md-1 mt-2 mt-md-0">
                                    <img :src="screenUMTwo" alt="Ondergrondse Moord gameplay" style="cursor: pointer;" @click="openLightbox(2)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenUMThree" alt="Ondergrondse Moord online escape room" style="cursor: pointer;" @click="openLightbox(3)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenUMFour" alt="Ondergrondse Moord screen" style="cursor: pointer;" @click="openLightbox(4)" loading="lazy"/>
                                </div>
                            </div>
                            {{ __('underground-murder.goal') }}
                        </div>
                        <div class="pb-4" v-if="room.id === 3">
                            <h2>{{ __('Het verhaal') }}</h2><br />
                            {{ __('detention.story') }}<br />
                            <div class="row mx-0 my-4">
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pr-md-1 mt-2 mt-md-0">
                                    <img :src="screenOne" :alt="__('detention.name') + ' screenshot'" style="cursor: pointer;" @click="openLightbox(1)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 px-md-1 mt-2 mt-md-0">
                                    <img :src="screenTwo" :alt="__('detention.name') + ' gameplay'" style="cursor: pointer;" @click="openLightbox(2)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenThree" :alt="__('detention.name') + ' online escape room'" style="cursor: pointer;" @click="openLightbox(3)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenFour" :alt="__('detention.name') + ' screen'" style="cursor: pointer;" @click="openLightbox(4)" loading="lazy"/>
                                </div>
                            </div>
                            {{ __('detention.goal') }}
                        </div>
                        <div class="pb-4" v-if="room.id === 4">
                            <h2>{{ __('Het verhaal') }}</h2><br />
                            <div v-html="__('artifact-isle.story')"></div>
                            <div class="row mx-0 my-4">
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pr-md-1 mt-2 mt-md-0">
                                    <img :src="screenAIOne" :alt="__('artifact-isle.name') + ' screenshot'" style="cursor: pointer;" @click="openLightbox(1)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 px-md-1 mt-2 mt-md-0">
                                    <img :src="screenAITwo" :alt="__('artifact-isle.name') + ' gameplay'" style="cursor: pointer;" @click="openLightbox(2)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenAIThree" :alt="__('artifact-isle.name') + ' online escape room'" style="cursor: pointer;" @click="openLightbox(3)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenAIFour" :alt="__('artifact-isle.name') + ' screen'" style="cursor: pointer;" @click="openLightbox(4)" loading="lazy"/>
                                </div>
                            </div>
                            {{ __('artifact-isle.goal') }}
                        </div>
                        <div class="pb-4" v-if="room.id === 5">
                            <h2>{{ __('Het verhaal') }}</h2><br />
                            <div v-html="__('the-saloon.story')"></div>
                            <div class="row mx-0 my-4">
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pr-md-1 mt-2 mt-md-0">
                                    <img :src="screenTSOne" :alt="__('the-saloon.name') + ' screenshot'" style="cursor: pointer;" @click="openLightbox(1)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 px-md-1 mt-2 mt-md-0">
                                    <img :src="screenTSTwo" :alt="__('the-saloon.name') + ' gameplay'" style="cursor: pointer;" @click="openLightbox(2)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenTSThree" :alt="__('the-saloon.name') + ' online escape room'" style="cursor: pointer;" @click="openLightbox(3)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenTSFour" :alt="__('the-saloon.name') + ' screen'" style="cursor: pointer;" @click="openLightbox(4)" loading="lazy"/>
                                </div>
                            </div>
                            {{ __('the-saloon.goal') }}
                        </div>
                        <div class="pb-4" v-if="room.id === 6">
                            <h2>{{ __('Het verhaal') }}</h2><br />
                            <div v-html="__('merlins-castle.story')"></div>
                            <div class="row mx-0 my-4">
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pr-md-1 mt-2 mt-md-0">
                                    <img :src="screenMCOne" :alt="__('merlins-castle.name') + ' screenshot'" style="cursor: pointer;" @click="openLightbox(1)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 px-md-1 mt-2 mt-md-0">
                                    <img :src="screenMCTwo" :alt="__('merlins-castle.name') + ' gameplay'" style="cursor: pointer;" @click="openLightbox(2)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenMCThree" :alt="__('merlins-castle.name') + ' online escape room'" style="cursor: pointer;" @click="openLightbox(3)" loading="lazy"/>
                                </div>
                                <div class="col-10 offset-1 offset-md-0 col-md-3 px-0 pl-md-1 mt-2 mt-md-0">
                                    <img :src="screenMCFour" :alt="__('merlins-castle.name') + ' screen'" style="cursor: pointer;" @click="openLightbox(4)" loading="lazy"/>
                                </div>
                            </div>
                            {{ __('merlins-castle.goal') }}
                        </div>
                        <highscore-component
                            v-if="room.active"
                            :room="room"
                        />
                        <div class="experiment pt-5">
                            <h2>{{ __('rooms.crosssell.title') }}</h2><br />
                            <span v-html="__('rooms.crosssell.description')"></span>
                        </div>
                    </div>
                    <div class="col-12 col-md-3 relative">
                        <product-purchase-component
                            v-if="room.active"
                            :product="this.product"
                            :room="this.room"
                            :event-time="this.eventTime"
                        />
                        <template
                            v-else
                        >
                            <div class="pb-4 py-md-4 text-center text-md-right">
                                <div class="fast-delivery d-inline-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hourglass-split" viewBox="0 0 16 16">
                                        <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z"/>
                                    </svg>
                                    {{ __('checkout.coming-soon') }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <LightBox
            v-if="lightBoxLoaded && this.media.length > 0"
            :showLightBox="false"
            :showThumbs="false"
            :showFooterCount="false"
            :media="media"
            :ref="'lightbox'"
        ></LightBox>
    </div>
</template>

<script>
import VoucherComponent from "../VoucherComponent";
import LightBox from 'vue-image-lightbox';

export default {
    name: "ProductRoomComponent",
    components: {VoucherComponent, LightBox},
    props: ['product', 'room', 'eventTime'],
    data() {
        return {
            media: [],
            lightBoxLoaded: false,
        }
    },
    computed: {
        assetPath() {
            return "https://dgb3ryo0lspid.cloudfront.net"; //process.env.ASSET_PATH || "";
        },
        assetPathTwo() {
            return process.env.ASSET_PATH || "";
        },
        screenOne() {
            return this.assetPath + "/images/detention/screens/screen-1.png";
        },
        screenTwo() {
            return this.assetPath + "/images/detention/screens/screen-2.png";
        },
        screenThree() {
            return this.assetPath + "/images/detention/screens/screen-3.png";
        },
        screenFour() {
            return this.assetPath + "/images/detention/screens/screen-4.png";
        },
        screenAIOne() {
            return this.assetPath + "/images/artifact-isle/screen-1.png";
        },
        screenAITwo() {
            return this.assetPath + "/images/artifact-isle/screen-2.png";
        },
        screenAIThree() {
            return this.assetPath + "/images/artifact-isle/screen-3.png";
        },
        screenAIFour() {
            return this.assetPath + "/images/artifact-isle/screen-4.png";
        },
        screenUMOne() {
            return this.assetPathTwo + "/images/underground-murder/um-screen-1.png";
        },
        screenUMTwo() {
            return this.assetPathTwo + "/images/underground-murder/um-screen-2.png";
        },
        screenUMThree() {
            return this.assetPathTwo + "/images/underground-murder/um-screen-3.png";
        },
        screenUMFour() {
            return this.assetPathTwo + "/images/underground-murder/um-screen-4.png";
        },
        screenENOne() {
            return this.assetPathTwo + "/images/" + this.__('expedition-northpole/screens/screen-1.png');
        },
        screenENTwo() {
            return this.assetPathTwo + "/images/" + this.__('expedition-northpole/screens/screen-2.png');
        },
        screenENThree() {
            return this.assetPathTwo + "/images/" + this.__('expedition-northpole/screens/screen-3.png');
        },
        screenTSOne() {
            return this.assetPath + "/images/the-saloon/screen-1.png";
        },
        screenTSTwo() {
            return this.assetPath + "/images/the-saloon/screen-2.png";
        },
        screenTSThree() {
            return this.assetPath + "/images/the-saloon/screen-3.png";
        },
        screenTSFour() {
            return this.assetPath + "/images/the-saloon/screen-4.png";
        },
        screenMCOne() {
            return this.assetPath + "/images/merlins-castle/backgrounds/background-screen-4-3.png";
        },
        screenMCTwo() {
            return this.assetPath + "/images/merlins-castle/screenshots/screenshot-2.png";
        },
        screenMCThree() {
            return this.assetPath + "/images/merlins-castle/screenshots/screenshot-3.png";
        },
        screenMCFour() {
            return this.assetPath + "/images/merlins-castle/screenshots/screenshot-4.png";
        },
        thumbnail() {
            return this.assetPath + "/images/" + this.__('detention.thumbnail');
        },
    },
    mounted() {
        this.initMedia();

        this.$nextTick(() => {
            this.lightBoxLoaded = true;
        });
    },
    methods: {
        openLightbox(image)
        {
            this.$refs.lightbox.showImage(image - 1);
        },
        initMedia()
        {
            let media = [];

            switch(this.room.id) {
                case 1:
                    media = media.concat([
                        { // For image
                            thumb: this.screenENOne,
                            src: this.screenENOne,
                        },
                        { // For image
                            thumb: this.screenENTwo,
                            src: this.screenENTwo,
                        },
                        { // For image
                            thumb: this.screenENThree,
                            src: this.screenENThree,
                        }
                    ]);
                    break;
                case 2:
                    media = media.concat([
                        { // For image
                            thumb: this.screenUMOne,
                            src: this.screenUMOne,
                        },
                        { // For image
                            thumb: this.screenUMTwo,
                            src: this.screenUMTwo,
                        },
                        { // For image
                            thumb: this.screenUMThree,
                            src: this.screenUMThree,
                        },
                        { // For image
                            thumb: this.screenUMFour,
                            src: this.screenUMFour,
                        }
                    ]);
                    break;
                case 3:
                    media = media.concat([
                        { // For image
                            thumb: this.screenOne,
                            src: this.screenOne,
                        },
                        { // For image
                            thumb: this.screenTwo,
                            src: this.screenTwo,
                        },
                        { // For image
                            thumb: this.screenThree,
                            src: this.screenThree,
                        },
                        { // For image
                            thumb: this.screenFour,
                            src: this.screenFour,
                        }
                    ]);
                    break;
                case 4:
                    media = media.concat([
                        { // For image
                            thumb: this.screenAIOne,
                            src: this.screenAIOne,
                        },
                        { // For image
                            thumb: this.screenAITwo,
                            src: this.screenAITwo,
                        },
                        { // For image
                            thumb: this.screenAIThree,
                            src: this.screenAIThree,
                        },
                        { // For image
                            thumb: this.screenAIFour,
                            src: this.screenAIFour,
                        }
                    ]);
                    break;
                case 5:
                    media = media.concat([
                        { // For image
                            thumb: this.screenTSOne,
                            src: this.screenTSOne,
                        },
                        { // For image
                            thumb: this.screenTSTwo,
                            src: this.screenTSTwo,
                        },
                        { // For image
                            thumb: this.screenTSThree,
                            src: this.screenTSThree,
                        },
                        { // For image
                            thumb: this.screenTSFour,
                            src: this.screenTSFour,
                        }
                    ]);
                    break;
                case 6:
                    media = media.concat([
                        { // For image
                            thumb: this.screenMCOne,
                            src: this.screenMCOne,
                        },
                        { // For image
                            thumb: this.screenMCTwo,
                            src: this.screenMCTwo,
                        },
                        { // For image
                            thumb: this.screenMCThree,
                            src: this.screenMCThree,
                        },
                        { // For image
                            thumb: this.screenMCFour,
                            src: this.screenMCFour,
                        }
                    ]);
                    break;
            }

            this.media = media;
        }
    },
}
</script>

